<template>

  <div class="row" ref="contactUsView">

    <div class="col-12">

      <card>
        <div slot="header">
          <div class="header-content">
            <div class="form-title">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
            <div class="header-actions">
              <router-link   v-if="$store.getters['auth/checkAccess']('job_requests/get')"
                             v-tooltip.top-center="'Go back'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/job-requests/list'">
                <span class="btn-label px-2">
                    <i class="fas fa-arrow-left" style="font-size: 30px"></i>
                  </span>
              </router-link>
<!--              <a v-if="$store.getters['auth/checkAccess']('job_requests/export')"-->
<!--                 v-tooltip.top-center="'Print'"-->
<!--                 class="btn-primary btn-simple btn-link"-->
<!--                 @click="printDownload">-->
<!--                  <span class="btn-label px-2">-->
<!--                    <i class="fas fa-print" style="font-size: 30px;color: #15aabf"></i>-->
<!--                  </span>-->
<!--              </a>-->
<!--              <a v-if="$store.getters['auth/checkAccess']('job_requests/export')"-->
<!--                 v-tooltip.top-center="'Download as pdf'"-->
<!--                 class="btn-primary btn-simple btn-link"-->
<!--                 @click="downloadPdf">-->
<!--                  <span class="btn-label px-2">-->
<!--                    <i class="fas fa-file-pdf" style="font-size: 30px;color: #15aabf"></i>-->
<!--                  </span>-->
<!--              </a>-->
            </div>
          </div>
        </div>

        <div id="contactus-message" class="card-body">
          <div class="container">
            <div class="row contact-info">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="typo-line">
                  <p>
                    <span class="category">First Name</span>
                    {{ getValueOrDash(itemDetails, 'first_name') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Last Name</span>
                    {{ getValueOrDash(itemDetails, 'last_name') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">DOB</span>
                    {{ getValueOrDash(itemDetails, 'dob') }}
                  </p>
                </div>
                 <div class="typo-line">
                  <p>
                    <span class="category">Street Address</span>
                    {{ getValueOrDash(itemDetails, 'street_address') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">City</span>
                    {{ getValueOrDash(itemDetails, 'city') }}
                  </p>
                </div>
                                <div class="typo-line">
                  <p>
                    <span class="category">State</span>
                    {{ getValueOrDash(itemDetails, 'state') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Zip Code</span>
                    {{ getValueOrDash(itemDetails, 'zip_code') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Email</span>
                    {{ getValueOrDash(itemDetails, 'email') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Phone</span>
                    {{ getValueOrDash(itemDetails, 'phone') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Date</span>
                    {{ getValueOrDash(itemDetails, 'created_at') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Position Type</span>
                    {{ getValueOrDash(itemDetails, 'position_type') }}
                  </p>
                </div>
                <h4> Experirnce </h4>
                <div class="typo-line">
                  <p>
                    <span class="category">Years Of Experience</span>
                    {{ getValueOrDash(itemDetails, 'years_of_experience') }}
                  </p>
                </div>
                <div class="typo-line">
                  <p>
                    <span class="category">Has License</span>
                    {{ getValueOrDash(itemDetails, 'license') }}
                  </p>
                </div>
                <div  v-if="getValueOrDash(itemDetails, 'license')" class="typo-line">
                  <p>
                    <span class="category">License Number</span>
                    {{ getValueOrDash(itemDetails, 'license_number') }}
                  </p>
                </div>
              </div>
            </div>


            <div class="category">Role :</div>
            <p class="message-content">
              {{ getValueOrDash(itemDetails, 'role') }}
            </p>
            <div class="category">Jobs :</div>
            <div v-if="itemDetails" class="items-row"  style="display: flex;    justify-content: space-evenly;">
              <p v-for="job in itemDetails.jobs" class="message-content">
                {{job.name}}
              </p>
            </div>
            <div class="category">Resume and other files :</div>
            <div v-if="itemDetails"  class="items-row" style="display: flex;    justify-content: space-evenly;">
              <a v-for="file in itemDetails.files" :href="getUrl()+ file.file" target="_blank">
                <i class="fas fa-file-pdf pdf-file-icon p-2" style="font-size: 50px"></i>
              </a>
            </div>

          </div>
        </div>

        <div class="card-footer text-right">

        </div>
      </card>


    </div>

  </div>
</template>
<script>
import { jsPDF } from "jspdf";

export default {

  components: {},

  data() {
    return {
      id: undefined,
      formTitle: "",
      submitting: false,

      itemDetails: null,

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.itemView
    });

    this.id = this.$route.params['id'];
    this.formTitle = "Job Request Details";
    if (this.id !== undefined) {
      this.getItem();
    } else {
      this.$notify({
        message: "Job Request Not Found",
        timeout: 2000,
        type: 'danger'
      });
      this.loader.hide();
    }
  },


  methods: {
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },
    getItem() {
      this.axios.post("job-requests/get", {id: +this.id}).then((response) => {
        this.itemDetails = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.$notify({
            message: "Job Request Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    getValueOrDash(obj, key) {
      if (obj && obj[key]) {
        return obj[key];
      }
      return "-";
    },
    printDownload () {
      let w = window.open()
      w.document.write(document.getElementById('contactus-message').innerHTML)
      w.document.close();
      w.setTimeout(function () {
        w.print()
      }, 1000)
    },
    downloadPdf(){




      // this.axios.post('contact-us/download-pdf',{id: +this.id})
      //   .then((response) => {
      //     console.log(response)
      //   });



      const pdf = new jsPDF({
        orientation: 'l',
        unit: 'px',
        format: 'letter',
        putOnlyUsedFonts:false,
        floatPrecision: 5 // or "smart", default is 16
      });
      // pdf.html(document.getElementById('contactus-message').innerHTML, {
      //   callback: function (pdf) {
      //     pdf.save('DOC.pdf');
      //   }, maxWidth: 1000
      // })
        pdf.text(document.getElementById('contactus-message').innerText,15, 15, {
          maxWidth: 550
        });
      pdf.save('DOC.pdf');

    }
  }
}
</script>

<style>
.header-content{
  display: flex;
  justify-content: space-between;
}
.typo-line{
  padding-left: 175px;
  margin-bottom: 20px;
}
.contact-info{
  margin-bottom: 20px;
}
.category{
  color: #888888;
}
/*.message-content{*/
/*  font-size: 16px !important*/
/*}*/
.items-row p, .items-row a{
  flex: 1;
}
.items-row p:first-child, .items-row a:first-child {
  margin-left: 0;
}
</style>
