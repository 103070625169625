<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Job Request List</h4>
        </div>

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :api-url="'job-requests/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link   v-if="$store.getters['auth/checkAccess']('job-requests/get')"
                              v-tooltip.top-center="'Show'"
                              class="btn-warning btn-simple btn-link"
                              :to="'/job-requests/show/'+scope.row.id">
                  <i class="fa fa-eye"></i>
                </router-link>

                <a v-if="$store.getters['auth/checkAccess']('job-requests/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this request?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <export-modal id="export-modal" title="Job Requests" url="job-requests/export"
              :filters="exportFilters" :modals="modals"></export-modal>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'First Name', value: 'first_name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'last_name', minWidth: '200', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'City', value: 'city', minWidth: '200', align: 'center'},
        {label: 'Phone', value: 'phone', minWidth: '200', align: 'center'},
          {label: 'Date', value: 'created_at', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [
        {
          key: 'job_requests.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.first_name',
          type: 'text',
          frontType:'simple',
          label: 'First Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.last_name',
          type: 'text',
          frontType:'simple',
          label: 'Last Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.phone',
          type: 'text',
          frontType:'simple',
          label: 'Phone',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.city',
          type: 'text',
          frontType:'simple',
          label: 'City',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        // {
        //   key: 'job_requests.text_message',
        //   type: 'text',
        //   frontType:'simple',
        //   label: 'Text message',
        //   class: 'col-md-12 col-xs-12',
        //   value: null,
        // },
        {
          key: 'job_requests.role',
          type: 'text',
          frontType:'simple',
          label: 'Role',
          class: 'col-md-12 col-xs-12',
          value: null,
        },
      ],
      exportFilters: [
        {
          key: 'job_requests.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.first_name',
          type: 'text',
          frontType:'simple',
          label: 'First Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.last_name',
          type: 'text',
          frontType:'simple',
          label: 'Last Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.phone',
          type: 'text',
          frontType:'simple',
          label: 'Phone',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.city',
          type: 'text',
          frontType:'simple',
          label: 'City',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.text_message',
          type: 'text',
          frontType:'simple',
          label: 'Text message',
          class: 'col-md-12 col-xs-12',
          value: null,
        },
        {
          key: 'job_requests.role',
          type: 'text',
          frontType:'simple',
          label: 'Role',
          class: 'col-md-12 col-xs-12',
          value: null,
        },
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL
    }

  },
  created() {
      this.responsiveViewPort();
  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("job-requests/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Request deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
        }else{
            this.fixedColumn = 'right';
        }
    }
  },
}
</script>

